































































































import ClientSelectInput from '@/components/inputs/ClientSelectInput.vue';
import Component from 'vue-class-component';
import SiteSelectInput from '@/components/inputs/SiteSelectInput.vue';
import Vue from 'vue';
import { Client, Patient, Practitioner, Site } from '@/models';
import { UsersViewAllLayout } from '../../shared';
import { Watch } from 'vue-property-decorator';
import { debouncedSearch } from '@/utils/inputSearch';
import { routeNames } from '@/router/routeNames';

@Component({
  name: 'PractitionersIndexPage',
  components: {
    UsersViewAllLayout,
    ClientSelectInput,
    SiteSelectInput,
  },
})
export default class extends Vue {
  practitioners: Practitioner[] = [];
  total = 0;
  loading = false;

  options = {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [false],
  };

  routeNames = routeNames;

  search = '';
  client: Client | null = null;
  site: Site | null = null;

  headers = [
    { text: 'First Name', value: 'data.firstName', groupable: false },
    { text: 'Last Name', value: 'data.lastName', groupable: false },

    { text: 'Email', value: 'data.email', sortable: false, groupable: false },
    { text: 'Phone', value: 'data.phone', sortable: false, groupable: false },
    // { text: 'Last Seen', value: 'last_seen', sortable: true, groupable: false },
    { text: 'Actions', value: 'actions', sortable: false, groupable: false },
  ];

  showSelect = false;

  searchUsers = debouncedSearch(() => this.loadUsers());

  async loadUsers(): Promise<void> {
    this.loading = true;

    const target = Practitioner.builder();

    let { data, meta } = await target
      .sortBy(this.options.sortBy[0], this.options.sortDesc[0])
      .where('name', this.search)
      .paginate(this.options.page, this.options.itemsPerPage);

    this.practitioners = data;
    this.total = meta.total;
    this.loading = false;
  }

  viewEmployee(patient: Patient): void {
    this.$router.push({
      name: routeNames.practitioner.practitioners.view,
      params: { uuid: patient.uuid },
    });
  }

  @Watch('options', { deep: true })
  onOptionsChanged(): void {
    this.loadUsers();
  }

  @Watch('search')
  onSearchChanged(): void {
    this.searchUsers();
  }

  @Watch('client')
  async onClientChanged(): Promise<void> {
    this.loadUsers();
  }

  @Watch('site')
  async onSiteChanged(): Promise<void> {
    this.loadUsers();
  }
}
