var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('users-view-all-layout',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: _vm.routeNames.practitioner.practitioners.create })}}},[_vm._v(" Add Practitioner ")])]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"flex-grow-1",attrs:{"items":_vm.practitioners,"headers":_vm.headers,"show-select":_vm.showSelect,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },"server-items-length":_vm.total,"no-data-text":"No practitioners found"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.viewEmployee},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"py-2",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{ref:"search",staticClass:"mx-1",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search Practitioners","hide-details":"","solo":"","flat":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(item.data.primaryRole))])]}},{key:"item.lastLogin",fn:function(ref){
      var item = ref.item;
return [(item.data.lastLogin)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data.lastLogin)))]):_c('span',[_vm._v("...")])]}},{key:"item.client",fn:function(ref){
      var item = ref.item;
return [(item.client)?_c('div',[_vm._v(_vm._s(item.client.data.name))]):_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v("Location Incomplete")])]}},{key:"item.site",fn:function(ref){
      var item = ref.item;
return [(item.site)?_c('div',[_vm._v(_vm._s(item.site.data.name))]):_c('v-chip',{attrs:{"dark":"","small":"","color":"warning"}},[_vm._v("Location Incomplete")])]}},{key:"item.access_status",fn:function(ref){
      var item = ref.item;
return [_c('v-avatar',{staticClass:"mb-1 v-avatar--metronome",attrs:{"color":item.status == 'active' ? 'success' : 'warning',"size":"12"},on:{"click":function($event){(_vm.editingStatus = true), (_vm.selected = item)}}})]}},{key:"item.last_seen",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data.lastSeen))+" ")]}},{key:"item.actions",fn:function(){return [_c('v-chip',{staticClass:"mr-2",attrs:{"color":"primary","small":""}},[_vm._v("Profile ")])]},proxy:true}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }